<div class="card">
    <div style="display: flex; align-items: center; gap: 20px;">
        <div class="detail-card" style="display: flex !important; flex-direction: row !important; align-items: center !important; gap: 5px;">
            <p class="simple-text">Nome: </p>
            <p class="bold-text">{{user.nome}}</p> 
        </div>
        <div class="detail-card" style="display: flex !important; flex-direction: row !important; align-items: center !important; gap: 5px;">
            <p class="simple-text">E-mail: </p>
            <p class="bold-text">{{user.email}}</p> 
        </div>
        <div class="detail-card" style="display: flex !important; flex-direction: row !important; align-items: center !important; gap: 5px;">
            <p class="simple-text">Senha: </p>
            <p class="bold-text"> ******** </p> 
        </div>
    </div>
    <div class="btn-container">
        <button mat-raised-button color="primary" class="new-scale-btn" (click)="handleEditClick()">Editar</button>
    </div>
</div>